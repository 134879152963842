<template>
  <Landing />
</template>

<script lang="ts">
import Vue from 'vue';
import Landing from './pages/Landing.vue';

export default Vue.extend({
  name: 'App',
  components: {
    Landing
  }
});
</script>

<style lang="scss">

</style>
