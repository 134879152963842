<template>
  <div class="text-center">
    <h1 class="font-weight-bold">
      History of Jeffreys Bay Golf Club
    </h1>

    <section class="w-50 mx-auto mt-5">
      <h5 class="font-weight-bold">
        The Original Course
      </h5>

      <p class="mt-3">
        The first course was founded in 1946 by Messrs, Harry Bidden, Sonny Collings, Tommie Ferreira and Dr. H.J. Schoeman.
      </p>

      <p>
        The course was orriginally laid out by a Scotsman, Mr. McElvery, and built on the slopes of where Strelitzia, Sable and Petunia Streets currently are.
        The greens were fine shellgrid and the club house use to be in the old Beach Hotel. The course turned out to be a nightmare, especially when the westerly
        wind was blowing and the ball could end up in the rough very close to Da Gama Street.
      </p>

      <p>
        A new course had to be built due to residential expansion projects.
      </p>
    </section>

    <section class="w-50 mx-auto mt-5">
      <h5 class="font-weight-bold">
        The Current Course
      </h5>

      <p class="mt-3">
        The new course was commissioned in 1965 and designed by a Mr. Allan Brooks.
      </p>

      <p>
        Mr. Pieter Vermaak a retired farmer from the Lady Grey area and previous residentin the Kouga decided to offer his services in building the course in
        conjunction with the municipality of Jeffreys Bay.
      </p>

    </section>

    <section class="w-50 mx-auto mt-5">
      <h5 class="font-weight-bold mt-3">
        The development of the course in short:-
      </h5>
      <ul class="text-left">
        <li>The municipality of Jeffreys Bay bought the +/-98 hectares from the Ungerer family for 8000 pounds.</li>
        <li>The old farmhouse, currently the Bowling Club, was used as the Clubhouse.</li>
        <li>Course developed by Mr. Allen Brooks, a professional golfer at the time.</li>
        <li>Mr. Manie Potgieter, building contractor, assisted with building of the course when he was available.</li>
        <li>The current 8th hole and fairway used to be hole no. 1, and current 7th hole, was the 9th hole.</li>
        <li>
          No mechanical tools were available and the course was built by labourers using spades, picks and wheelbarrows. But,
          "n volbloed boer en sy span gee nooit op en maak klaar kom wat will".
        </li>
        <li>
          Mr Kobus Vermaak, son of Pieter Vermaak, also assisted with the building of the course during the June holidays, Kobus played a major rle in the building
          and development of the greens of the current 3rd, 5th, and 8th holes. Back breaking work which left you with a sore body every night (to quote him).
        </li>
        <li>The fairways were cut by a "Bossiekapper" which enabled the natural grass to grow.</li>
        <li>In 1975 the Munisipality of Jeffreys Bay build the Jeffreys Bay Country Club which incorporated the bowling, tennis, squash and golf clubs.</li>
        <li>During the 1990's the country club started to disband and in 1996 the Jeffreys Bay Golf Club obtained it's independance.</li>
      </ul>
    </section>

    <section class="w-50 mx-auto mt-5">
      <h5 class="font-weight-bold">
        About the Course Builder - Baanmaker
      </h5>

      <ul class="text-left">
        <li>Pieter Vermaak, born 8 June 1912</li>
        <li>Schooled in Humansdorp in his primary years and later Oakdale Agricultural School in Riversdale.</li>
        <li>Went farming in Lady Gray in 1948. Farmed for his uncle who was sickly and later inherited the farm from his uncle. Farmed until 1965 before retiring to Jeffreys Bay.</li>
        <li>Pieter was a very active and passionate golf player who won many open events in Jeffreys Bay and loved spending time with his friends on the golf course.</li>
      </ul>
    </section>

    <section class="w-50 mx-auto mt-5">
      <h5 class="font-weight-bold">
        Official Launching of the Course
      </h5>

      <p class="mt-3">
        The course was officially opened on 2 December 1969 by the then President of South Africa, Mr. John Voster, Mr Pieter Vermaak who was going to play with the president was omitted
        from the prestiges 4 ball by the then committee of the club. Rumours have it that it was because of "political differences" that he was refused to play in that 4 ball.
        These rumours could, however, not be confirmed.
      </p>
    </section>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

// interface ComponentProps {

// }

// interface ComponentData {
// }

// interface ComponentComputed {
// }

// interface ComponentMethods {
// }

export default Vue.extend<unknown, unknown, unknown, unknown>({
  name: 'HistoryComponent',
  data() {
    return {
    }
  },

  computed: {

  },

  methods: {

  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
